<script setup>
import { reactive, ref } from 'vue';
import { getCurrentLocale, LOCALE_OPTIONS, switchLocale } from '@/locales';
import logger from '@/common/logger';
import useAuthStore from '@/store/authStore';
import router from '@/router';

const locales = reactive(LOCALE_OPTIONS);
const selectObjLocale = LOCALE_OPTIONS.find((obj) => obj.value === getCurrentLocale());
const selectedLocale = ref(selectObjLocale);

const profileMenuItems = ref([
    {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog'
    },
    {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
            const auth = useAuthStore();
            auth.logout();
            router.go('/')
        }
    }
]);

const changeLanguage = (selected) => {
    if (selected != null && selected.value !== getCurrentLocale()) {
        logger.debug(`Change locale to: ${selected.name}`);
        switchLocale(selected.value);
    }
};

const profilePopupRef = ref();
const toggleLanguage = (event) => {
    profilePopupRef.value.toggle(event);
};
</script>

<template>
    <button class="layout-topbar-action" type="button" @click="toggleLanguage">
        <i class="pi pi-user"></i>
    </button>
    <Popover ref="profilePopupRef" class="p-lang-menu">

        <Menu :model="profileMenuItems" class="w-full md:w-60">
            <template #start>
                <button v-ripple
                        class="relative overflow-hidden w-full border-0 bg-transparent flex items-start p-2 pl-4 hover:bg-surface-100 dark:hover:bg-surface-800 rounded-none cursor-pointer transition-colors duration-200">
                    <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                            shape="circle" />
                    <span class="inline-flex flex-col items-start">
                        <span class="font-bold">Amy Elsner</span>
                        <span class="text-sm">Admin</span>
                    </span>
                </button>
            </template>
            <template #submenulabel="{ item }">
                <span class="text-primary font-bold">{{ item.label }}</span>
            </template>
            <template #item="{ item, props }">
                <a v-ripple class="flex items-center" v-bind="props.action">
                    <span :class="item.icon" />
                    <span>{{ item.label }}</span>
                    <Badge v-if="item.badge" class="ml-auto" :value="item.badge" />
                    <span v-if="item.shortcut"
                          class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1">{{ item.shortcut
                        }}</span>
                </a>
            </template>
            <template #end>

            </template>
        </Menu>


    </Popover>
</template>

<style lang="scss"></style>
