import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import DEFAULTS from '@/constants/app';

const useAuthStore = defineStore('authStore', () => {
    const expiration = ref();
    const permissions = ref([]);
    const accessToken = ref(localStorage.getItem(DEFAULTS.ACCESS.TOKEN));

    const isAuthenticated = computed(() => {
        return expiration.value !== null && expiration.value !== undefined && new Date().getTime() < expiration.value;
    });

    const getPermissions = computed(() => permissions);

    function updateExpiration() {
        const time = Number(localStorage.getItem(DEFAULTS.ACCESS.EXPIRATION));
        if (!isNaN(time)) {
            expiration.value = time;
        }
    }

    function updatePermission() {
        const permissionRawData = localStorage.getItem(DEFAULTS.ACCESS.PERMISSIONS) ?? '[]';
        permissions.value = JSON.parse(permissionRawData);
    }

    function logout() {
        localStorage.removeItem(DEFAULTS.ACCESS.EXPIRATION);
        localStorage.removeItem(DEFAULTS.ACCESS.PERMISSIONS);
        localStorage.removeItem(DEFAULTS.ACCESS.TOKEN);
        updateAuth();
    }

    function updateAuth() {
        updateExpiration();
        updatePermission();
    }

    return { isAuthenticated, updateExpiration, getPermissions, updatePermission, updateAuth, logout };
});

export default useAuthStore;
