const DEFAULTS = {
    ACCESS: {
        TOKEN: 'AppAccessToken',
        EXPIRATION: 'AppAccessExpiration',
        PERMISSIONS: 'AppAccessPermissions'
    },
    CACHE: {
        CURRENT_STORES: 'selectedStores',
        CURRENT_CATEGORIES: 'selectedCategories',
        CURRENT_ALL_CATEGORIES: 'selectedAllCategories',
        CURRENT_MOCKUP_3D: 'selectedMockup3d',
        CURRENT_MOCKUP_2D: 'selectedMockup2d',
        CURRENT_PRODUCT_TYPE: 'selectedProductType',
        CURRENT_ORDER_IMAGE: 'selectedOrderImage',
    },
    TOAST_TIMEOUT: {
        ERROR: 3000,
        WARN: 3000,
        INFO: 3000,
        SUCCESS: 3000
    }
};

export default DEFAULTS;
